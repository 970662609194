import { QuestionAnswerSharp } from '@mui/icons-material';
import { update } from 'lodash';
import React, {useState, useEffect} from 'react'
import { updatePoll } from '../services/questions/polls'
import QRCode from 'react-qr-code';

export default function Poll({pollId, setPollId}) {
    const [question, setQuestion] = useState("")
    const [questionOptions, setQuestionOptions] = useState([])
    const [update, setUpdate] = useState(false)
    const [link, setLink ] = useState('')
    const [hideResults, setHideResults] = useState(false)
    const [intervalId, setIntervalId] = useState(null)

    useEffect(() => {
        if (pollId) {
            $('.pollPopUp').css({
              display: 'block'
            });
            setUpdate(true)
            // TODO make this a hook?
            setIntervalId(setInterval(updateAnswers, 500));

            setLink(`${window.location.origin}/polls/${pollId}/respond`)
        } else {
            clearInterval(intervalId)
            setIntervalId(null)
            hidePoll()
        }
    }, [pollId])
    
    function updateAnswers() {
        if (pollId) {
            const response = updatePoll(pollId)
            response.then((data)=> {
                setQuestion(data.question)
                setQuestionOptions(data.options)
            })
        }
    }

    function hidePoll() {
        $('.pollPopUp').css({
            display: 'none'
        });
        setPollId(null)
        setUpdate(false)
    }

    function toggleHideResults() {
        setHideResults(!hideResults)
    }

    function optionPercent(count) {
        const total = questionOptions.map(x => x.count).reduce((partialSum, a) => partialSum + a, 0)
        if (total === 0) {
            return Math.round(100 / questionOptions.length)
        } else {
            return Math.round(count * 100/ total) 
        }
    }


    return (

        <div className='modal pollPopUp'>
            <div className="pollContainer">
                <div className='optionsHeader'>{question}</div>
                <div className='optionsContainer'>
                    <ul className='pollList'>
                        {questionOptions.map((option) =>
                            <li className='pollOptionItem'key={option.id}>
                                <div className='pollOption'>
                                    {option.answer}
                                </div>
                                { !hideResults &&
                                    <div>
                                        <div className="meter orange nostripes">
                                            <span style={{width:`${optionPercent(option.count)}%`}}>{option.count}</span>
                                        </div>
                                    </div>
                                }
                            </li>
                        )}
                    </ul>
                </div>
                <div className='pollFooter'>
                    <div className='pollLink'>
                        Head over to {link} to answer
                    </div>
                    {pollId && (
                        <div className='pollQr'>
                            <QRCode
                            title="PollLink"
                            value={link}
                            bgColor='#FFFFFF'
                            fgColor='#000000'
                            size={64}
                            />
                        </div>
                    )}
                    <div className='pollButtonContainer'>
                        <div className='closePoll pollButton btn btn-default btn-inverse' onClick={hidePoll}>
                            Close
                        </div>
                        <div className='hideResults pollButton btn btn-default btn-inverse' onClick={toggleHideResults}>
                            { hideResults ? 'Show Results' : 'Hide Results'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
