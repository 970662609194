import React, { useState} from 'react';


const NoteWidget = ({createNote}) => {
    const [note, setNote] = useState('')
    function closePanel() {
        $('#notesTool').css({
            display: 'none'
        });
    }

    function handleChange(e) {
        setNote(e.target.value)
    }

    function submitNote() {
        closePanel()
        createNote(note)
    }

    return (
        <div className="modal" id="notesTool">
            
        
            <div className="notes-container">
                <div className="notes-container-child">
                    <h3 className="notes-header" id="modal-title">Add a note</h3>
                    <textarea className="notes-form" rows="3" placeholder="" onChange={handleChange} ></textarea>
                    <div className="notes-button-group">
                        <button onClick={submitNote} type="button" className="notes-submit-button">Submit</button>
                        <div onClick={closePanel} className="notes-cancel-button">Cancel</div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default NoteWidget;