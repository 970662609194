export function accountStyle(accountStyle, ebookStyle) {
  let styles;

  switch (ebookStyle){
    case 'poptential':
      styles = poptentialStyles(accountStyle);
      break;
    case 'softlySpoken':
      styles = softlySpokenStyles(accountStyle); 
      break;
  }
    

    return styles;
};


function poptentialStyles(accountStyle) {
  const styles = `
  @import url('https://fonts.googleapis.com/css?family=Oswald:300,400,600,700|Source+Sans+Pro:300,400,600,700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Corben:wght@700&display=swap')
  @import url('https://fonts.googleapis.com/css2?family=Mukta:wght@700&display=swap')
  @import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;700;900&display=swap')
  
  #myFrame {
    border: 0;
    padding: 0;
    margin: 0;
    background: 50% 50% no-repeat;
    font-size: 14px;
    overflow: scroll;
    background-color: #ffffff;
  }

  #frameBody {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 80vh;
    margin: 0;
    padding: 75px;
    font-family: 'Source Sans Pro', sans-serif;
    color: #000000;
    background-color:#ffffff;
  }

  @media(min-width: 768px) {
    #myFrame html {
      font-size: 16px;
    }
  }

  @media only screen and (max-width: 700px) {
    

    #frameBody {
      padding: 20px;
    }

    #myFrame .poptential-header {
      margin-top:  0px !important;
      margin-left: -20px !important;
      margin-right: -20px !important;
      height: 400px !important;
    }

    #myFrame .poptential-header.unit {
      margin-top: 30px !important;
      
    }

     #myFrame p.header-quote {
      margin-left: 0px !important;
      margin-right: 0px !important;
     }

     #myFrame .poptential-header.unit .poptential-header__title {
      width: 107% !important;
     }

     #myFrame .teacher-content__header__title p{
      font-size: 17px;
     }

    .poptential_image_container {
      margin-left: -45px;
      margin-right: -45px;
    }

    .poptential-header__chapter {
      display: none !important;
    }

    .poptential-logo-header {
      display: none !important;
    }

    #myFrame .question-block .ebook-quiz ul  {
      padding-left: 0px;
    }

    #myFrame .question-block .ebook-quiz ul li {
      min-width: 100px;
    }

    .poptential-header__title h2 {
      font-size: 6vmin !important;
    }

    #myFrame .centered-div-block blockquote {
      width: 100vw;
      margin: auto;
      margin-left: -26px;
    }

    #myFrame .centered-div-block blockquote pre {
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  #frameBody .poptential {
    font-family: 'Noto Serif JP', serif;
    font-weight: 400;
    margin:0;
  }

  #frameBody .poptential > *:not(.poptential-header) {
    padding: 0 10px;
  }
  @media(min-width: 768px) {
    #frameBody .poptential > *:not(.poptential-header) {
      padding: 0px 40px;
    }
  }
  #myFrame .font-size-13 p {
    font-size: 13px;
  }
  #myFrame .font-size-14 p {
    font-size: 14px;
  }
  #myFrame .font-size-15 p {
    font-size: 15px;
  }
  #myFrame .font-size-16 p {
    font-size: 16px;
  }
  #myFrame .font-size-17 p {
    font-size: 17px;
  }
  #myFrame .font-size-18 p {
    font-size: 18px;
  }
  #myFrame .font-size-19 p {
    font-size: 19px;
  }
  #myFrame .font-size-20 p {
    font-size: 20px;
  }

  #myFrame .font-size-13 i {
    font-size: 13px;
  }
  #myFrame .font-size-14 i {
    font-size: 14px;
  }
  #myFrame .font-size-15 i {
    font-size: 15px;
  }
  #myFrame .font-size-16 i {
    font-size: 16px;
  }
  #myFrame .font-size-17 i {
    font-size: 17px;
  }
  #myFrame .font-size-18 i {
    font-size: 18px;
  }
  #myFrame .font-size-19 i {
    font-size: 19px;
  }
  #myFrame .font-size-20 i {
    font-size: 20px;
  }

  #myFrame .font-size-13 ol li{
    font-size: 13px;
  }
  #myFrame .font-size-14 ol li{
    font-size: 14px;
  }
  #myFrame .font-size-15 ol li{
    font-size: 15px;
  }
  #myFrame .font-size-16 ol li{
    font-size: 16px;
  }
  #myFrame .font-size-17 ol li{
    font-size: 17px;
  }
  #myFrame .font-size-18 ol li{
    font-size: 18px;
  }
  #myFrame .font-size-19 ol li{
    font-size: 19px;
  }
  #myFrame .font-size-20 ol li{
    font-size: 20px;
  }

  #myFrame .font-size-13 blockquote pre {
    font-size: 13px;
  }
  #myFrame .font-size-14 blockquote pre {
    font-size: 14px;
  }
  #myFrame .font-size-15 blockquote pre {
    font-size: 15px;
  }
  #myFrame .font-size-16 blockquote pre {
    font-size: 16px;
  }
  #myFrame .font-size-17 blockquote pre {
    font-size: 17px;
  }
  #myFrame .font-size-18 blockquote pre {
    font-size: 18px;
  }
  #myFrame .font-size-19 blockquote pre {
    font-size: 19px;
  }
  #myFrame .font-size-20 blockquote pre {
    font-size: 20px;
  }

  #myFrame p {
    margin: 20px 0;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.4;
  }
  #myFrame h1, h2, h3, h4, h5, h6 { 
    text-align: left;
    font-family: ${accountStyle.font};
    margin: 20px 0 10px;
  }
  
  #myFrame h1 {
    font-family: ${accountStyle.font};
    line-height: 1.2;
    color: ${accountStyle.primaryColor};
    font-size: 2rem;
    font-weight: 300;
  }
  
  #myFrame h2 {
    font-weight: 300;
    font-size: 2.25rem;
    line-height: 1.2;
    font-weight: 400;
    color: #6a7a90;
    font-family: 'Oswald', sans-serif;
  }
  #myFrame h3 {
    font-weight: 800;
    font-size: 2rem;
    line-height: 1.1;
    color:${accountStyle.primaryColor};
  }h2
  
  #myFrame h4 {
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 1.1;
    color:${accountStyle.primaryColor};
  }
  
  #myFrame h5 {
    font-weight: 600;
    font-size: 0.8125rem;
    line-height: 1;
    color:${accountStyle.primaryColor};
    margin: 5px 0;
  }
  
  #myFrame h6 {
    font-weight: 600;
    font-size: 0.8125rem;
    line-height: 1;
    color: ${accountStyle.primaryColor};
    text-transform: uppercase; 
    margin: 5px 0;
  }
  #myFrame hr {
    border-top: 3px double #cccccc;
    width: 100%;
    margin: 15px 0;
  }
  #myFrame h1 + hr {
    margin-top:0px;
    left: 0;
    // position: absolute;
  }
  #myFrame ul { 
    display: inline-block;
    list-style-type:disc; 
  }
  #myFrame ol { list-style-type:decimal; }
  #myFrame ol, ul { 
    margin-left: 0;
    list-style-position: outside;
    margin-bottom: 20px;
    padding-left: 10px;
  }
  
  #myFrame ol li, ul li {
    margin-left:10px;
    color:#000000;
    font-family: 'Source Sans Pro', sans-serif;
    margin-bottom: 5px;
  }

  #myFrame body.poptential ol 
  #myFrame body.poptential li, 
  #myFrame body.poptential ul,
  #myFrame body.poptential li {
    font-family: 'Noto Serif JP', serif;
  }

  #myFrame li p {
    color: inherit;
    font-family: inherit;
    margin: 0;
    padding: 0;
  }
  #myFrame a {
    text-decoration:underline;
    color: ${accountStyle.primaryColor};
    font-size: 15px;
  }
  #myFrame .entry-link {
    color: black;
    text-decoration: underline;
  }
  #myFrame .smallImg{
    width: 1%;
  }
  #myFrame .highlighted-container.highlight-yellow {
    background-color: rgb(255, 255, 123) !important;
  }
  #myFrame .highlighted-container.highlight-pink {
    background-color: #f48fb1 !important;
  }
  #myFrame .highlighted-container.highlight-green {
    background-color: #9ef953 !important;
  }
  #myFrame .highlighted-container.highlight-blue {
    background-color: #29b6f6 !important;
  }

  .deleted-annotation {
    padding: 0rem;
    background-color: white !important;
  }
  /*
  #myFrame .highlighted-container{
    position: relative;
  }
  */
  #myFrame .has-note {
    margin-bottom: 2px dashed blue;
  }
  #myFrame .fa-check{
    color: green;
  }
  #myFrame .fa-times{
    color: red;
  }
  #myFrame .reimagine-quiz ol{
    cursor: pointer;
  }
  #myFrame .question, .takeaway, .finished {
    margin: 20px 0;
  }
  #myFrame .question-header, .takeaway-header, .finished-header {
    padding: 10px;
    margin-bottom: 2px;
    background-color: rgb(234,234,234);
    color: ${accountStyle.primaryColor};
    font-weight: 300;
    letter-spacing: 1px;
  }
  #myFrame .question-block ul li {
    min-width: 250px;
  }
  #myFrame .question-block ul li label{
    padding-left: 15px;
    padding-right: 15px;
  }
  #myFrame @media(min-width: 768px) {
    .question-header, .takeaway-header, .finished-header {
      padding: 20px;
    }
  }
  #myFrame .question-content, .takeaway-content, .finished-content {
    padding: 10px;
    background-color: rgb(234,234,234);
  }
  #myFrame @media(min-width: 768px) {
    .question-content, .takeaway-content, .finished-content {
      padding: 10px 20px;
    }
    .question-content {
      padding-left: 20px;
    }
  }
  #myFrame .ebook-quiz-submit {
    color: #fff;
    background-color: ${accountStyle.primaryColor};
    padding: 10px 20px;
  }
  #myFrame .quizQuestion {
    color:${accountStyle.primaryColor};
    font-family: 'Oswald', sans-serif;
    font-size: 1.2rem;
    font-weight: 300;
  }
  #myFrame .ebook-quiz ul {
    list-style-type: none;
    margin-left: 0;
    
  }
  
  #myFrame .ebook-quiz ul li {
    margin-bottom: 10px;
    margin-left: 0;
  }
  #myFrame .ebook-quiz ul li span, .ebook-quiz ul li label {
    padding-left: 10px;
    font-family: 'Oswald', sans-serif;
    color: #000;
  }
  #myFrame .ebook-short-answer-quiz textarea {
    border: solid 1px #333;
  }
  #myFrame .multiple-choice-explaination {
    display: none;
  }
  
  #myFrame .question-content + h3.test-knowledge { 
    display: none; 
  }
  #myFrame .hidden{
    display: none;
  }
  #myFrame .annotation-note, .annotation-bookmark  {
    position: relative;
    left: 0px;
  }
  #myFrame .fa-bookmark{
    color: #337ab7;
    width:30px;
    height:30px;
    font-size:14px !important;
    padding:7px 0;
    text-align:center;
    background-color:rgba(255,255,255,0.8);
    box-sizing:border-box;
    border-radius:50%;
    cursor:pointer;
    -moz-box-shadow: 0px 2px 4px rgba(0,0,0,0.3);
    -webkit-box-shadow: 0px 2px 4px rgba(0,0,0,0.3);
    box-shadow: 0px 2px 4px rgba(0,0,0,0.3);
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.2s ease;
  }
  #myFrame .fa-file, .fa-times{
    display:block;
    color: #337ab7;
    width: 30px;
    height:30px;
    font-size:14px !important;
    padding: 7px 0;
    text-align:center;
    background-color:rgba(255,255,255,0.8);
    box-sizing:border-box;
    border-radius:50%;
    cursor:pointer;
    -moz-box-shadow: 0px 2px 4px rgba(0,0,0,0.3);
    -webkit-box-shadow: 0px 2px 4px rgba(0,0,0,0.3);
    box-shadow: 0px 2px 4px rgba(0,0,0,0.3);
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.2s ease;
  }
  #myFrame .fa-file:hover, .note-active{
    width:40px;
    height:40px;
    margin-left:-5px;
    padding:12px 0;
    margin-top:-5px;
    color: #ffffff;
    background-color:#337ab7;
  }
  #myFrame .annotations-notes-lnk{
    cursor: pointer;
  }
  #myFrame .annotations-bookmarks-lnk{
    cursor: pointer;
  }
  #myFrame .post-it {
    background:#fefabc;
    padding:15px;
    font-family: monospace;
    font-size:15px;
    color: #000;
    width:200px;
    z-index:1;
    top:30px;
    left:30px;
    height: 150px;
    overflow-x: auto;
    position: absolute;
    -moz-transform: rotate(7deg);
    -webkit-transform: rotate(7deg);
    -o-transform: rotate(7deg);
    -ms-transform: rotate(7deg);
    transform: rotate(1deg);
    -moz-box-shadow: 2px 3px 16px rgba(0,0,0,0.15), 0px 1px 4px rgba(0,0,0,0.1);
    -webkit-box-shadow: 2px 3px 16px rgba(0,0,0,0.15), 0px 1px 4px rgba(0,0,0,0.1);
    box-shadow: 2px 3px 16px rgba(0,0,0,0.15), 0px 1px 4px rgba(0,0,0,0.1);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
  }
  #myFrame .btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: @btn-font-weight;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
  }
  #myFrame .btn-primary {
      color: #fff;
      background-color: ${accountStyle.primaryColor};
      border-color: ${accountStyle.secondaryColor};
  }
  #myFrame li p {
    margin: 0;
    margin-bottom: 0px;
  }
   
  #myFrame video {
    height: auto;
    max-width: 90vw;
  }
  
  #myFrame .video-container {
    display: flex;
    position: relative;
    margin-bottom: 30px;
    cursor: pointer;
    width: 100%;
    text-align: center;
    flex-direction: column;
    align-items: center;
  }

  #myFrame .video-container p {
    font-weight: bold;
  }

  .video-with-description {
    display: inline-block;
  }

  .internal-video-wrapper {
    width: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .video-description {
    max-width: 640px;
    width: inherit !important;
    text-align: right;
  }

  #myFrame .video-description {
    text-align: left;
  }

  #myFrame .video-description p {
    font-size: 1.25rem !important;
    font-weight: 100;
    text-align: center;
  }

  #myFrame .video-description p i {
    font-size: 1.25rem !important;
    font-weight: 100;
  }


  #myFrame .video-title {
    margin: 25px 10px 10px 10px;
    padding-left: 10px;
    text-align: center;
    font-size: 26px;
    display: block;
  }
  #myFrame .video-play-image {
    position: absolute;
    top: 38%;
    left: 45%;
    height: 75px;
    opacity: .8;
    cursor: pointer;
  }
  
  #myFrame .video-play-image:hover {
    opacity: .7;
  }
  #myFrame {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  #myFrame img {
    display: block;
    padding-top: 10px;
    margin: auto;
    margin-bottom: 15px;
  }
  
  #myFrame figcaption, figcaption p {
    font-size: .9em;
    text-align: center;
    margin: 0 auto 10px;
    line-height: 1.2;
    max-width: 500px;
    font-weight: 700;
    word-wrap: break-word;
  }
  #myFrame audio {
    display: block;
    margin-top: 20px;
    margin-bottom: 25px;
  }
  
  #myFrame .centered-div-block {
    display: inline-block;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  #myFrame .centered-div-block h2 {
    text-align: center;
  }
  
  #myFrame .mif {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
  }
  #myFrame .mif iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  #myFrame @font-face {
    font-family: "FontAwesome";
    font-weight: normal;
    font-style : normal;
           src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?v=4.3.0");
           src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?#iefix&v=4.3.0") format("embedded-opentype"),
                 url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff2?v=4.3.0") format("woff2"),
                 url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff?v=4.3.0") format("woff"),
                 url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.ttf?v=4.3.0") format("truetype"),
                 url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular") format("svg");
  }
  #myFrame .video-thumbnail {
    position: relative;
    display: block; 
    cursor: pointer;
    margin: 0px;
  }
  #myFrame .ebook-short-answer-quiz textarea {
       background: #ffffff;
       display:inline-block!important; 
       max-width: 90%;
       width: 90%;
  }
  
  #myFrame button {
    padding:5px;
    color: #fff;
    background-color: ${accountStyle.primaryColor};
    border-color: ${accountStyle.primaryColor};
  }
  
  #myFrame .video-thumbnail:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    content: "\\f01d";
    font-family: FontAwesome;
    font-size: 75px;
    color: #fff;
    opacity: .8;
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
    z-index:2;
  }
  
  #myFrame .video-thumbnail:hover:before {
    color: #eee;
  }  
  
  #myFrame .video-tracking a {
    z-index:10;
    position: relative;
    display: block; 
    cursor: pointer;
    margin: 0px;
  }

 

  #myFrame img.big {
    max-width: 85%;
    max-height: 150%;
    height: auto;
    width: auto;
  }
  #myFrame img {
    max-width: 75%;
    max-height: 90%;
    height: auto;
    width: auto;
    margin:auto;
  }



  
  #myFrame figcaption {
    max-width: 70%;
    text-align:left;
    margin-left:0;
    font-size: 1.25rem;
    font-weight: 100;
    text-align: center;
    margin: auto;
  }

  #myFrame figcaption p {
    text-align:center;
    margin: auto;
    
  }
  
  #myFrame pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
  }
   
  #myFrame  {
    max-width:80vw;
    max-height: 50vw;
  }
  
  @media only screen and (max-width: 640px) {
    #myFrame .embedd-videos {
      height: 400px !important;
      width:550px !important;
    }
  }
  
  @media only screen and (max-width: 480px) {
    #myFrame .embedd-videos {
      height: 240px !important;
      width: 320px !important;
    }
  }
  
  :focus { outline:0; }
  b,b *,strong,strong *{font-weight:700}
  button,input,input:not([type]),input[type=color],input[type=text],input[type=time],input[type=url],input[type=week],input[type=button],input[type=reset],input[type=submit],input[type=date],input[type=datetime],input[type=datetime-local],input[type=email],input[type=month],input[type=number],input[type=password],input[type=search],input[type=tel],pre,select,textarea{font:inherit}
  
  #myFrame .codepen body{margin:10px 0 0; }
  #myFrame .codepen body textarea{display:none}
  #myFrame textarea {
    display:inline-block !important; 
    max-width: 90%;
    width: 90%;
  }
  #myFrame table{width:100%;border-spacing:0;border-collapse:separate;border:1px solid #aaa}
  #myFrame table tr:nth-child(even){background:#FAFAFA}
  #myFrame table caption, table td, table th{padding:15px 7px;font:inherit}
  
  #myFrame table th{font-weight:400;color:#6E6E6E;background-position:100% 100%;background-size:2px 10px;background-repeat:no-repeat}
  #myFrame table th:last-child{background:0 0}
  
  #myFrame .questions{
    font-family: 'Open Sans', sans-serif;
    line-height: 1.4em;
    color: #000000!important;
  }
  
  #myFrame blockquote {
    font-style: italic; 
    font-weight:400;
    margin-left: 1.7em;
    width: 120%;
    margin-left: -125px;
    color:${accountStyle.secondaryColor};
  }

  #myFrame .ebook-multiple-choice-quiz ul {
    list-style: none !important;
    width: 100%
  }

  #myFrame body.poptential .ebook-multiple-choice-quiz ul {
    padding-left:0;
  }

  #myFrame .teacher-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  #myFrame @media (min-width: 768px) {
    .teacher-block {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  

  #myFrame .teacher-content {
    flex-basis: 49%;
    margin: 20px 0;
    width: 100%;
  }

  // #myFrame .teacher-content__header {
  //   display: flex;
  //   background: #007790;
  //   font-weight: bold;
  //   color: white;
  //   font-size: 20px;
  //   line-height: 24px;
  //   flex-direction: column
  // }

  #myFrame .teacher-content__header {
    display: flex;
    background: ${accountStyle.primaryColor};
    background: linear-gradient(90deg, ${accountStyle.secondaryColor} 0%, ${accountStyle.primaryColor} 100%);
    font-weight: bold;
    color: white;
    font-size: 20px;
    line-height: 24px;
    flex-direction: column
  }

  #myFrame .teacher-content__header__title {
    padding: 10px;
    display: flex;
    align-items: center;
  }

  #myFrame .teacher-content__header__title p {
    font-family: 'Mukta';
    font-size: 23px;
    font-weight: bold;
    color: black;
  }

  #myFrame body.poptential .teacher-content__header__title p {
    font-family:'Mukta';
    font-size: 23px;
    font-weight: bold;
    color: black;
  }

  #myFrame .teacher-content__header__title img {
    margin: 0 10px 0 0;
    height: 30px;
    padding: 0;
  }

  #myFrame .teacher-content__header__subtitle {
    background-color: #B7DEE8;
    color: #007790;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    padding: 2px 10px 2px 50px;
  }

  #myFrame .teacher-content hr {
    margin: 4px 0;
    color: lightgray
  }

  #myFrame .teacher-content__content {
    padding: 10px 15px;
    color: #007790;
    font-size: 14px
  }

  #myFrame .teacher-content__content p {
    color: #007790;
  }

  #myFrame .teacher-content__content a {
    display: block;
    color: #000;
    font-family: 'Mukta';
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
  }

  #myFrame body.poptential .teacher-content__content a {
    color: #000;
    font-family: 'Mukta';
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
  }

  #myFrame body > h2:first-child {
    font-weight:400;
    font-size:13px;
    margin: 10px 0 5px;
    text-transform:uppercase;
  }
  #myFrame body > h1:nth-child(2) {
    font-weight:400;
    font-size:36px;
    margin-top:0;
  }

  #myFrame .popup-open > * {
    pointer-events:none;
  }

  #myFrame .popup-content {
    background:rgba(0,0,0,0.2);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    left: 0;
    height: 100%;
    top: 0;
    transition:0.3s ease;
    pointer-events:none;
    opacity:0;
  }

  #myFrame .popup-content > div {
    width: 75%;
    margin: 0 auto;
    height: 75%;
    background: #fff;
    border-radius: 15px;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid${accountStyle.secondaryColor};
  }

  #myFrame .popup-content > div > .popup-close {text-align:right;}

  #myFrame .popup-content.open {
    opacity:1;
    pointer-events:all;
  }

  #myFrame .img-zoom-container {
    position: relative;
  }
  
  #myFrame .js-image-zoom__zoomed-image {pointer-events:none!important;}
  #myFrame .js-image-zoom__zoomed-area {pointer-events:none!important;}
  

  // #myFrame body .poptential-header,
  // #myFrame body .flex-container {
  //   display:block;
  // }

  #myFrame .poptential-header {
    background: ${accountStyle.primaryColor};
    background: linear-gradient(135deg, ${accountStyle.secondaryColor} 0%, ${accountStyle.primaryColor} 100%);
    display: block;
    padding:60px 0;
    margin-top: -96px;
    margin-left: -75px;
    margin-right: -75px;
    margin-bottom: 50px;
  }

  #myFrame .poptential-header__chapter {
    color: black;
    font-family: 'Mukta';
    font-size: 19px;
    
    font-weight: bold;
    position: relative;
    top: -81px;
    left: 78px;
    text-align: right;
    color: black;
    font-family: 'Mukta';
    font-weight: 'bold';
    font-weight: bold;
    font-size: 20px;
    width: 86%;
  }
  

  #myFrame .poptential-header__gradient {
    background: ${accountStyle.primaryColor};
    background: linear-gradient(135deg, ${accountStyle.secondaryColor} 0%, ${accountStyle.primaryColor} 100%);
    align-items: flex-end;
    height: 340px;
    position: relative;
    bottom: 272px;
    padding: 40px 0;
    z-index: 2;
  }

  #myFrame .header-quote {
    font-size: 24px;
    width: 75%;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 100;
    padding-top: 15px;
  }

  #myFrame .poptential-header__image {
    background: #000;
    width: 75%;
    margin: 0 auto;
    height: 45vw; 
    max-height: 400px;
  }

  #myFrame .poptential-header h2 {
    color:#000;margin: 0;
  }
  #myFrame .poptential-header__title {
    border: 7px solid #000;
    padding: 20px 40px;
    margin:0 auto;
    width: 86%;
  }

  .poptential-header__title h2 {
    padding: 25px 0;
  }
  #myFrame .poptential-header.unit {
    background: none;
    margin-top: 40px;
    padding: 0;
    height: inherit;
  }

  #myFrame .poptential-header.unit .poptential-header__image {display:flex;align-items:center;position: relative;z-index: 3;}
  #myFrame .poptential-header.unit .poptential-header__title {
    padding: 20px 20px 20px 60px;
    padding-left: 60px;
    position: absolute;
    left: -30px;
    width: 60%;
    padding-left: 60px;
  }

  #myFrame .flex-container {display: flex;padding-top:20px!important;padding-bottom:20px!important;}
  #myFrame .flex-container img {margin:0;padding:0;width: 100%;}
  #myFrame .flex-container > div {flex: 1;margin: 0 10px;}
  @media screen and (max-width: 450px) {
    .flex-container {flex-direction:column;}
  }

  #myFrame .question-header {
    background:none;
    padding-top: 40px;
    border-top: 3px dotted #000;
    color: #000;
    font-family: 'Mukta';
    font-size: 33px;
    font-weight: bold;
    text-align:center;
  }

  #myFrame .question-content {
    background: none;
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
  }

  #myFrame .quizQuestion {
    color: #000;
    font-size: 20px;
    font-weight: 900;
    margin-top: 0;
  }

  #myFrame .ebook-quiz ul li input {
    display:none;
  }

  #myFrame .ebook-quiz ul li span, .ebook-quiz ul li label {
    background: #000;
    color: #fff;
    display: block;
    font-family:'Mukta';
    font-size:18px;
    font-weight:bold;
    padding: 5px;
    border-radius: 8px;
  }

  #myFrame .ebook-quiz ul li label:hover {
    background: ${accountStyle.primaryColor}
  }

  #myFrame .ebook-quiz ul li input:checked + label {
    background: ${accountStyle.primaryColor}
  }

  #myFrame .fa.fa-check {
    color: ${accountStyle.primaryColor};
    font-size: 20px;
    margin-right: 10px;
  }

  #myFrame .fa.fa-times {
    color: red;
    font-size: 20px;
    margin-right: 10px;
    box-shadow: none;
  }

  #myFrame .multiple-choice-submit.ebook-quiz-submit {
    display: none;
  }

  #myFrame .question {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
  }

  #myFrame .teacher-content__content a {margin: 10px 0;}
  #myFrame .teacher-content__content a::before {
    background: #000;
    border-radius: 4px;
    color: #fff;
    content:'View';
    float: right;
    margin-left: 10px;
    text-align:center;
    width: 80px;
  }

  #myFrame .teacher-content__content a::before:hover {
    background: rgb(255,140,73);
  }

  #myFrame blockquote {
    background-color: #000;
    box-sizing: border-box;
    font-style:normal;
    font-size: 30px;
    margin: 0;
    margin-bottom: 80px;
    padding: 20px;
    position: relative;
    width: 120%;
    margin-left: -10%;
    margin-right: -200px;
    background: linear-gradient(90deg, ${accountStyle.secondaryColor} 0%, ${accountStyle.primaryColor} 100%);

  }

  #myFrame blockquote pre {
    margin: 0;
    white-space: normal;
    background: none;
    border: none;
    word-break: normal;
    font-family: 'Noto Serif JP';
    font-weight: bold;
    text-align: center;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 75px; 
    padding-bottom: 75px; 
  }

  #myFrame blockquote pre a {
    display: block;
    padding-top: 40px;
    color: black;
  }

  #myFrame i b {
    font-size: 1.75rem;
  }

  #myFrame i {
    font-size: 1.25rem;
  }
  
`;
  return styles;
}

function softlySpokenStyles(accountStyle) {
  const styles = `
    #myFrame {
      border: 0;
      padding: 0;
      margin: 0;
      background: 50% 50% no-repeat;
      font-size: 14px;
      overflow: scroll;
      background-color: #ffffff;
      font-family: "Roboto";
    }

    #frameBody {
      overflow-x: hidden;
      overflow-y: scroll;
      height: 80vh;
      margin: 0;
      font-family: 'Source Sans Pro', sans-serif;
      color: #000000;
      background-color:#ffffff;
    }

    .soft-heading {
      text-align: center;
      margin: auto;
      font-family: "Lora";
    }

    .soft-heading-1 {
      width: 60%;
      padding: 50px;
      padding-top: 100px
    }

    .soft-heading-2 {
      padding: 40px;
    }

    .soft-heading-3 {
      padding: 30px;
    }

    .soft-heading-4 {
      padding: 20px;
    }

    .soft-heading-5 {
      padding: 20px;
    }

    .soft-heading-6 {
      padding: 20px;
    }

    .soft-spoken-header {
      padding-top: 80px;
      font-family: 'Lora';
      text-align: center;
    }

    .soft-paragraph {
      line-height: 1.8;
      font-family: Arial, Helvetica, sans-serif;
      padding-left: 60px;
      padding-right: 60px;
    }

    .soft-paragraph:not(:first-of-type) {
      text-indent: 40px;
    }

    li .soft-paragraph {
      padding-left: 5px;
    }
    
    ul {
      padding-left: 80px;
    }

    #frameBody > .soft-image-container:first-child {
      margin: auto;
      width: 60%;
    }

    .soft-image-container:first-of-type .soft-image {
      width: 100%;
    }

    .soft-image-container {
      text-align: center;
      padding-top: 40px;
      padding-bottom: 40px;
    }

    .soft-image-container figcaption{
      font-size: 1rem;
      font-style: italic;
      padding: 10px;
    }

    .soft-image {
      width: 70%;
      margin: auto;
    }

    .video-container {
      display: inline-block;
      margin-right: auto;
      margin-left: auto;
      margin: auto;
      text-align: center;
      padding-right: 40px;
      width: 100%;
    }

    .soft-quote-block {
      width: 90%;
      margin: auto;
      display: block;
      text-align: center;
      padding: 20px;
      font-size: 16px;
      font-family:  "Zilla Slab";
    }

    .soft-quote-pre {
      display: block;
    }
    
    
    .soft-quote-block a {
      display: block;
      color: black;
      font-family: "Roboto";
      padding: 10px;
    }

    .soft-quote-block a:hover {
      color: black;
    }

    .question {
      background-color: #F5F5F6;
    }

    .soft-question-container {
      padding: 100px;
      text-align: center;
    }

    .question-header {
      margin: auto;
      text-align: center;
      font-family: "Lora";
    }

    .quizQuestion {
      margin: auto;
      text-align: center; 
      width: 70%;
      font-family: "Zilla Slab";
      padding: 30px;
    }

    .question-content {
      text-align: center;
    }

    #myFrame .multiple-choice-explanation {
      display: none;
    }

    #myFrame .ebook-quiz ul li {
      margin-bottom: 10px;
      margin-left: 0;
    }

    #myFrame .ebook-quiz ul li span, .ebook-quiz ul li label {
      background: #000;
      color: #fff;
      display: block;
      font-family: 'Mukta';
      font-size: 18px;
      font-weight: bold;
      padding: 5px;
      border-radius: 8px;
      text-align: center;
    }

    #myFrame .ebook-multiple-choice-quiz ul {
      list-style: none !important;
    }

    #myFrame .ebook-quiz ul li input {
      display: none;
    }

    .soft-spoken-mc-submit {
      text-align: center;
      margin: auto;
      padding: 40px;
      border: solid;
      border-width: 2px;
      border-color: black;
      border-radius: 30px;
      color: black;
      background: white;
    }

 
  `;

  return styles;
}
