import React from 'react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function TableOfContents({navigation, goToPage, title, econgame}) {
  
  function toggleTOC() {
    $("#ereaderTOC").toggle()
  }

  function toggleUnit(e) {
    if ($(e.target).hasClass('active')) {
      let moduleConainer = $(e.target.parentElement.parentElement.querySelector('.module-container'))
      $(e.target).removeClass('active')
      moduleConainer.removeClass('active')
      moduleConainer.addClass('hidden')
      $(e.target.parentElement.parentElement.querySelector('.module-container').querySelector('.module-item')).removeClass('active')
      $(e.target.parentElement.parentElement.querySelector('.module-container').querySelector('.module-item')).addClass('hidden')
      $(e.target.parentElement.parentElement.querySelector('.module-container').querySelector('.chapter-carrot')).addClass('hidden')
    } else {
      $(e.target).addClass('active')
      let moduleConainer = $(e.target.parentElement.parentElement.querySelector('.module-container'))
      moduleConainer.addClass('active')
      moduleConainer.removeClass('hidden')
      $(e.target.parentElement.parentElement.querySelector('.module-container').querySelector('.module-item')).addClass('active')
      $(e.target.parentElement.parentElement.querySelector('.module-container').querySelector('.module-item')).removeClass('hidden')
      $(e.target.parentElement.parentElement.querySelector('.module-container').querySelector('.chapter-carrot')).removeClass('hidden')
    }
  }
``
  function toggleModule(e) {
    if ($(e.target).hasClass('active')) {
      $(e.target).removeClass('active')
      $(e.target.parentElement.querySelector('.inner-chapters')).addClass('hidden')
    } else {
      $(e.target).addClass('active')
      $(e.target.parentElement.querySelector('.inner-chapters')).removeClass('hidden')

    }
  }

  return (
    <div className="menu-toggle-btn">
        <button type="button" onClick={toggleTOC} className="btn btn-primary btn-circle btn-lg navigation-button-open" data-toggle="tooltip" data-placement="bottom" title="Table of Contents">
            <span className="glyphicon glyphicon-menu-hamburger" aria-hidden="true"></span>
        </button>
        <div id="ereaderTOC" className="sidenav" style={{display: "none", left: "0px"}}>
            <p className="side-titles">Table of Contents</p>
            {econgame &&
              <p className="chapter-carrot" style={{ textAlign: "center"}}>
                <a href="http://economart.s3-website-us-east-1.amazonaws.com/" target="_blank">
                  Open Econ Game
                  <span style={{color: "#f58731", padding: "5px"}}>
                    → 
                  </span>
                </a>
              </p>
            }
            <p className="ebook-title">{title}</p>
            <ul className="table-of-contents">
              {navigation.map((item) =>
              !item.children.length > 0 ? (
                <div className="unit-item" key={item.name}>
                   <li>
                      <span className="chapter-carrot" data-order="undefined"></span><a href="#" className="lnk-toc" onClick={() => goToPage(item.id)}>{item.name}</a>
                    </li>
                </div>
              ) : (
                <div className="unit-item" key={item.name}>
                    <li>
                      <span className="chapter-carrot" data-order="undefined" onClick={toggleUnit}></span><a href="#" className="lnk-toc" onClick={() => goToPage(item.id)}>{item.name}</a>
                    </li>
                    <div className="module-container hidden"  style={{display: "block"}}>
                      <div className="module-item">
                        {item.children.map((subItem) => (
                            <li key={subItem.name}>
                              <span className="chapter-carrot" data-order="1" onClick={toggleModule}></span><a href="#" className="lnk-toc" onClick={() => goToPage(subItem.id)}>{subItem.name}</a>
                              <div className="inner-chapters hidden"  style={{display: "block"}}>
                                {subItem.children.map((subItem) => (
                                  <li key={subItem.name} style={{marginLeft: "26px"}}><a href="#" className="lnk-toc" data-order="2" onClick={() => goToPage(subItem.id)}>{subItem.name}</a></li>
                                ))}
                              </div>
                            </li>
                        ))}
                      </div>
                    </div>
                </div>
              )
            )}
            </ul>
        </div>
    </div>
  )
}
