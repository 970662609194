import React from 'react';
const BookmarkBar = ({bookmarks, changePage}) => {

    function toggleBookmarkBar() {
        $('#bookmarkBar').toggle()
    }

    return (
        <div id="bookmarkBar" className="sidedrawer" style={{right: "0px", display: "none"}}>
            <button type="button" className="btn btn-default btn-sm close-side" onClick={toggleBookmarkBar}>Close
            </button>
            <p className="side-titles">Bookmarks
            </p>
            <ul className="annotations-notes">
                {bookmarks.map((bookmark) =>
                    <li onClick={() => changePage(bookmark.page, true, true)} key={bookmark.id.toString()}>{bookmark.highlightedText}</li>
                )}
            </ul>
        </div>

    );
}

export default BookmarkBar;