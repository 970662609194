import React, {useState, useEffect} from 'react'
import StateDropdown from './StateDropdown'

const SchoolSearch = (props) => {
    const [state, setState] = useState('')
    const [schools, setSchools] = useState([])
    const [displayConfirm, setDisplayConfirm] = useState(props.confirm)
    const [agileSuggestionDisplay, setAgileSuggestionDisplay] = useState(props.schoolType == "no_school" || props.schoolType == "missing")
    const [schoolSuggestions, setSchoolSuggestions] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
      if (agileSuggestionDisplay) {
        fetch(`${window.location.origin}/schools/suggestion`)
          .then(response => response.json())
          .then(obj => {
            if (obj.suggestions) {
              setSchoolSuggestions(obj.suggestions)
              if (obj.suggestions.length == 0) {
                denySchool()
              }
            } else {
              denySchool()
            }
            setLoading(false)
          })
          .catch(error => {
            // Handle error
            console.error('Error fetching school suggestions:', error);
            setLoading(false); // Set loading to false in case of error
          });
      }
    },[agileSuggestionDisplay])
    
    useEffect(() => {
      console.log(`state changed to ${state}`)
    }, [state])


    function closeModal() {
      $('#schoolSearchModal').css({
        display: 'none'
      });
    }

    function closeAndReload() {
      closeModal()
      window.location.reload()
    }

    function handleNameChange(e) {
      searchSchools(e.target.value)
    }

    function searchSchools(name) {
      fetch(`${window.location.origin}/schools/search/${state}/${name}`)
        .then(response => response.json())
        .then(obj => {
          setSchools(obj.schools)
        })
    }

    function setSchool(e, id) {
        e.preventDefault()
        fetch(`${window.location.origin}/schools/add`, {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: id
            })
          }).then(response => response.json())
            .then(obj => {
                if (obj.success) {
                    console.log("added school")
                    closeAndReload()
                } else {
                    alert("Failed to add school")
                }
            })
    }

    function setAgileSchool(e, id) {
      e.preventDefault()
      fetch(`${window.location.origin}/schools/add_agile`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
            agile_id: id
        })
      }).then(response => response.json())
        .then(obj => {
            if (obj.success) {
                console.log("added school")
                closeAndReload()
            } else {
                alert("Failed to add school")
            }
        })
    }

    function submitHomeschool(e) {
        e.preventDefault()
        fetch(`${window.location.origin}/schools/add_homeschool`, {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
                state: state
            })
          }).then(response => response.json())
            .then(obj => {
                if (obj.success) {
                    console.log("added school")
                    closeAndReload()
                } else {
                    alert("Failed to add school")
                }
            })
    }

    function submitOtherSchool(e) {
        e.preventDefault()
        fetch(`${window.location.origin}/schools/add_other_school`, {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
                state: state
            })
          }).then(response => response.json())
          .then(obj => {
            if (obj.success) {
              console.log("added school")
              closeAndReload()
            } else {
                alert("Failed to add school")
            }
            
          })
    }

    function confirmSchool(e) {
      alert ("Thanks for confirming your school")
      e.preventDefault()
      fetch(`${window.location.origin}/schools/confirm`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        }
      }).then(response => response.json())
        .then(obj => {
            if (obj.success) {
                console.log("added school")
                closeAndReload()
            } else {
                alert("Failed to add school")
            }
        })
      
    }

    function displaySearch() {
        return (state.length === 2 )
    }

    function displayExit() {
        return (!props.display)
    }

    function denySchool() {
      setAgileSuggestionDisplay(false)
      setDisplayConfirm(false)
      //Update display
    }

    function differentSchool() {
      setAgileSuggestionDisplay(true)
    }

    return(
          <div className="modal fade in" id="schoolSearchModal" style={{display: props.display ? "block" : "none"}}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title center">Welcome!</h4>
                </div>
                <div className='modal-body' style={{display: agileSuggestionDisplay ? "block" : "none"}}>
                  <p>Is one of these your school?</p>
                  {loading &&
                    <div>
                      Loading...
                    </div>
                  }
                  {!loading &&
                    <>
                      {schoolSuggestions.map((school) =>
                        <button className="btn btn-primary" key={school.instUid} onClick={(e) => setAgileSchool(e, school.instUid)}><strong>{school.institutionNameProper}</strong> →</button>
                      )}
                      <button className="btn btn-secondary" onClick={denySchool}><strong>No, I don't see my school.</strong></button>
                    </>
                  }
                </div>
                <div style={{display: agileSuggestionDisplay ? "none" : "block"}}>
                  <div className='modal-body' style={{display: displayConfirm ? "block" : "none"}}>
                    <p>Would you mind confirming your school for us?</p>
                    <div className='row'>
                      <div className="col-md-10 col-md-offset-1">
                        { props.schoolType == "homeschool" && 
                          <p>
                            Are you still homeschooling?
                          </p>
                        }
                        
                        { props.schoolType != "homeschool" && props.schoolType != "missing" && 
                          <p>
                            Is it correct that you are a teacher at {props.current_school}
                          </p>
                        }
                        <button className="btn btn-secondary" onClick={confirmSchool}><strong>That's Correct</strong></button>
                        <button className="btn btn-secondary" onClick={differentSchool}><strong>No, I am at a different school</strong></button>
                      </div>
                    </div>
                  </div>
                  <div className="modal-body" style={{display: displayConfirm ? "none" : "block"}}>
                    <p>
                      Please tell us a little more about yourself
                    </p>
                    <div className="row">
                      <div className="col-md-10 col-md-offset-1">
                        <form>
                          <div className="form-group">
                            <label className="control-label">First, select your state:</label>
                            <StateDropdown setState={setState}/>
                          </div>
                          { displaySearch() &&
                              <div>
                                  <div className="form-group">
                                    <label className="control-label">Then, search for your school:</label>
                                    <input type="text" className="form-control input-lg" placeholder="Enter the name of your school" onChange={handleNameChange} />
                                  </div>
                                  {schools.map((school) =>
                                      <button className="btn btn-primary" key={school.id} onClick={(e) => setSchool(e, school.id)}><strong>{school.name}</strong> ({school.city}) →</button>
                                  )}
                                  <button className="btn btn-secondary" onClick={(e) => submitHomeschool(e)}><strong>I am homeschooling →</strong></button>
                                  <button className="btn btn-secondary" onClick={(e) => submitOtherSchool(e)}><strong>Other (or school not found) →</strong></button>
                              </div>
                          }
                        </form>
                      </div>
                    </div>
                    <br/>
                    <br/>
                  </div>
                </div>
              </div>
            </div>
          </div>
    )

}

export default SchoolSearch
