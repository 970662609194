// Taken from certell-web-api
import * as contentful from 'contentful';
import * as contentfulManagement from 'contentful-management';

const CONTENT_MANGEMENT_ACCESS_TOKEN =
  process.env.CONTENT_MANGEMENT_ACCESS_TOKEN;
export const SPACE_ID = process.env.SPACE_ID;
export const ENVIRONMENT_ID = process.env.CONTENTFUL_ENVIRONMENT_ID;

export const managementClient = contentfulManagement.createClient({
  accessToken: CONTENT_MANGEMENT_ACCESS_TOKEN
});

export const deliveryClient = contentful.createClient({
  space: SPACE_ID,
  accessToken: process.env.CONTENT_DELIVERY_ACCESS_TOKEN
});

export const previewClient = contentful.createClient({
  space: SPACE_ID,
  accessToken: process.env.CONTENT_PREVIEW_ACCESS_TOKEN,
  environment_id: 'master',
  host: 'preview.contentful.com'
});

export async function getEnvironment() {
  const space = await managementClient.getSpace(SPACE_ID);
  return space.getEnvironment(ENVIRONMENT_ID);
}

// async function getAllLocales() {
//   const env = await getEnvironment();
//   const locales = await env.getLocales();
//   return locales.items;
// }

// async function createChapter(
//   title,
//   contentfulBody,
//   parentChapterNumber = 0
// ) {
//   let publishedEntry = null;
//   const environment = await getEnvironment();
//   try {
//     const entry = await environment.createEntry('chapter', {
//       fields: {
//         title: {
//           'en-US': title
//         },
//         body: {
//           'en-US': contentfulBody
//         },
//         parentChapterNumber: {
//           'en-US': parentChapterNumber
//         }
//       }
//     });
//     publishedEntry = await entry.publish();
//   } catch (e) {
//     console.error(e);
//   }
//   return publishedEntry;
// }

// async function createAsset(name, path, contentType, description = '') {
//   const environment = await getEnvironment();
//   let s3file;
//   try {
//     s3file = await uploadToBucket(path, name);
//   } catch (err) {
//     console.error(`Unable to create asset: ${name} in path ${path}`);
//     console.error(err);
//     return null;
//   }

//   console.log('Creating...');
//   const asset = await environment.createAsset({
//     fields: {
//       title: {
//         'en-US': name
//       },
//       description: {
//         'en-US': description
//       },
//       file: {
//         'en-US': {
//           upload: s3file,
//           contentType,
//           fileName: name
//         }
//       }
//     }
//   });

//   console.log(`Processing asset ${name}`);
//   const processedAsset = await asset.processForAllLocales();
//   console.log(`Publishing asset ${name}`);
//   const publishedAsset = await processedAsset.publish();
//   console.log(`Published asset id: ${publishedAsset.sys.id}`);

//   return publishedAsset.sys.id;
// }

// async function uploadToBucket(path, fileName) {
//   // Fill in your bucket name and local file name:
//   const BUCKET_NAME = process.env.S3_TEMP_UPLOAD_BUCKET;
//   const FILE_NAME_LOCAL = path;
//   const FILE_NAME_S3 = fileName;
//   const FILE_PERMISSION = 'public-read';

//   // Get file stream
//   const fileStream = fs.createReadStream(FILE_NAME_LOCAL);

//   return uploadRawToBucket(
//     BUCKET_NAME,
//     FILE_NAME_S3,
//     fileStream,
//     FILE_PERMISSION
//   );
// }

// async function uploadPdfToBucket(path, fileName) {
//   // Fill in your bucket name and local file name:
//   const BUCKET_NAME = process.env.S3_TEMP_UPLOAD_BUCKET;
//   const FILE_NAME_LOCAL = path;
//   const FILE_NAME_S3 = fileName;
//   const FILE_PERMISSION = 'public-read';

//   // Get file stream
//   const fileStream = fs.createReadStream(FILE_NAME_LOCAL);

//   return uploadRichTextToBucket(
//     BUCKET_NAME,
//     FILE_NAME_S3,
//     fileStream,
//     FILE_PERMISSION
//   );
// }

// async function uploadRawToBucket(Bucket, Key, Body, ACL) {
//   const s3 = new AWS.S3({ apiVersion: '2006-03-01' });

//   const uploadParams = {
//     Bucket,
//     Key,
//     Body,
//     ACL
//   };

//   return new Promise((resolve, reject) => {
//     s3.upload(uploadParams, (err, data) => {
//       if (err) {
//         return reject(err);
//       }
//       return resolve(data.Location);
//     });
//   });
// }

// async function uploadRichTextToBucket(Bucket, Key, Body, ACL) {
//   const s3 = new AWS.S3({ apiVersion: '2006-03-01' });

//   const uploadParams = {
//     Bucket,
//     Key,
//     Body,
//     ACL
//   };

//   return new Promise((resolve, reject) => {
//     s3.upload(uploadParams, (err, data) => {
//       if (err) {
//         return reject(err);
//       }
//       return resolve(data.Location);
//     });
//   });
// }

// async function createVideo(srcId, coverImageId) {
//   console.log(`Creating video with ${srcId}  ${coverImageId}`);
//   let publishedEntry = null;
//   const environment = await getEnvironment();
//   try {
//     const params = {
//       fields: {
//         src: {
//           'en-US': {
//             sys: {
//               type: 'Link',
//               linkType: 'Asset',
//               id: srcId
//             }
//           }
//         }
//       }
//     };
//     if (coverImageId) {
//       params.fields.coverImage = {
//         'en-US': {
//           sys: {
//             type: 'Link',
//             linkType: 'Asset',
//             id: coverImageId
//           }
//         }
//       };
//     }

//     const entry = await environment.createEntry('video', params);
//     publishedEntry = await entry.publish();
//   } catch (e) {
//     console.error(e);
//   }
//   return publishedEntry;
// }

// async function createShortAnswerQuestion(question) {
//   console.log(`Creating question:${question} `);
//   let publishedEntry = null;
//   const environment = await getEnvironment();
//   try {
//     const params = {
//       fields: {
//         question: {
//           'en-US': question
//         }
//       }
//     };

//     const entry = await environment.createEntry('shortAnswerQuestion', params);
//     publishedEntry = await entry.publish();
//   } catch (e) {
//     console.error(e);
//   }
//   return publishedEntry;
// }

// async function createMultipleChoiceQuestions(question, answers) {
//   console.log(`Creating question:${question} `);
//   let publishedEntry = null;
//   const environment = await getEnvironment();
//   try {
//     const params = {
//       fields: {
//         question: {
//           'en-US': question
//         },
//         answers
//       }
//     };

//     const entry = await environment.createEntry(
//       'multipleChoiceQuestion',
//       params
//     );
//     publishedEntry = await entry.publish();
//   } catch (e) {
//     console.error(e);
//   }
//   return publishedEntry;
// }

// async function createMultipleChoiceAnswer(
//   answer,
//   isAnswerCorrect,
//   explanation = ''
// ) {
//   let publishedEntry = null;
//   const environment = await getEnvironment();
//   try {
//     const params = {
//       fields: {
//         answer: {
//           'en-US': answer
//         },
//         isAnswerCorrect: {
//           'en-US': isAnswerCorrect
//         },
//         explanation: {
//           'en-US': explanation
//         }
//       }
//     };

//     const entry = await environment.createEntry('multipleChoiceAnswer', params);
//     publishedEntry = await entry.publish();
//   } catch (e) {
//     console.error(e);
//   }
//   return publishedEntry;
// }

// async function getAsset(id) {
//   const environment = await getEnvironment();
//   return environment.getAsset(id);
// }

// async function createTeacherContent(teacherContent) {
//   const environment = await getEnvironment();
//   const markdown = await convertHTMLtoMarkdown(teacherContent.content);
//   const richText = await richTextFromMarkdown(markdown);
//   let published = null;
//   try {
//     const entry = await environment.createEntry('teacherContent', {
//       fields: {
//         teacherContentType: {
//           'en-US': teacherContent.teacherContentType
//         },
//         title: {
//           'en-US': teacherContent.title
//         },
//         content: {
//           'en-US': richText
//         }
//       }
//     });
//     published = await entry.publish();
//   } catch (err) {
//     console.log('err', err);
//   }
//   return published;
// }

// async function createBellRinger(bellRingerData) {
//   const environment = await getEnvironment();
//   const markdown = await convertHTMLtoMarkdown(bellRingerData.content);
//   const richText = await richTextFromMarkdown(markdown);
//   let published = null;
//   try {
//     const entry = await environment.createEntry('bellRinger', {
//       fields: {
//         title: {
//           'en-US': bellRingerData.title
//         },
//         date: {
//           'en-US': bellRingerData.date
//         },
//         body: {
//           'en-US': richText
//         },
//         imageUrl: {
//           'en-US': bellRingerData.imageUrl
//         },
//         videoUrl: {
//           'en-US': bellRingerData.videoUrl
//         },
//         tags: {
//           'en-US': []
//         }
//       }
//     });
//     published = await entry.publish();
//   } catch (err) {
//     console.log('err', err);
//   }
//   return published;
// }
