export async function createPoll(questionId, pageId, contentType, chapter) {
    const request = await fetch(`${window.location.origin}/polls`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
            contentful_q_id: questionId,
            page_id: pageId,
            ebook_type: contentType,
            chapter: chapter
        })
      })
     
      const data = await request.json()
      
      return data.data
  };

export async function updatePoll(pollId) {
    const request = await fetch(`${window.location.origin}/polls/${pollId}`, {
        method: 'Get',
        headers: {
          "Content-Type": "application/json",
        }
      })
     
      const data = await request.json()
      return data
}
