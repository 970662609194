export function logActivity(activityName, ebookId, pageId= null, currentTime = null, duration = null, chapterId = null, mediaId = null, parentType= null) {
  fetch(`${window.location.origin}/ereader/add_event`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      action: activityName,
      page: pageId,
      ebook_contentful_id: ebookId,
      current_time: currentTime,
      duration: duration,
      contentful_chapter_id: chapterId,
      media_id: mediaId,
      parent_type: parentType 
    })
  }).then(res => console.log(res))
  // console.log(`sending activity ${activityName}`)
}

export function saveUserAnswer(userAnswer, quizUUID, answerCorrect, ebookId, pageId) {
  fetch(`${window.location.origin}/ereader_quiz/submit_mc_answer`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      user_answer: userAnswer,
      quizUUID: quizUUID,
      answer_correct: answerCorrect,
      ebook_id: ebookId,
      page_id: pageId 
    })
  }).then(res => console.log(res))
}

export function saveUserResponse(userAnswer, quizUUID, ebookId, pageId) {
  fetch(`${window.location.origin}/ereader_quiz/submit_short_answer`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      user_answer: userAnswer,
      quizUUID: quizUUID,
      ebook_id: ebookId,
      page_id: pageId
    })
  }).then(res => console.log(res))
}