import {  extractMultipleChoiceQuestion } from '../contentful/repository'

export function getAllQuizAnswers(ebook) {  
    
    let ebookQuestions = [];

    if (!!ebook) {
        const entries = ebook.includes.Entry || [];
        const questions = entries.filter(
                (e) =>
                e.sys.contentType &&
                e.sys.contentType.sys.id === 'multipleChoiceQuestion'
            );
        ebookQuestions = questions.map((q) => extractMultipleChoiceQuestion(q))
    }
    
    return (ebookQuestions);
}

export function getUserQuizAnswers(ebookId) {
    console.log("GETTING USER ANSWERS")
    fetch(`${window.location.origin}/ereader/quiz_user_answers/${ebookId}`)
        .then(response => response.json())
        .then(obj => {
            return obj.data;
        });
    // $.ajax({
    //     type: 'GET',
    //     url,
    //     contentType: 'application/json',
    //     success(obj) {
    //         setState('quizUserAnswers', obj.data);
    //     },
    //     error(err) {
    //         console.log(err);
    //     }
    // });
}


  



/*
Quiz Process (From Certell)

1. In ereader initializer run getAllQuizAnswers and getUserQuizAnswers

2. GetAllQuizAnswer, sets state of quizQuestionAnswers
    -request to ebookQuizRouter '/api/ebookQuiz/:ebookUUID
    -maps through all MCquestions for that ebook
    -extractMultipleChoiceQuestion
        - returns {quizUUID, quizQuestion, dateCreated, score, quizAnswers: {
            quizAnswer, quizAnswerExplanation, quizAnswerUUID, isCorrect
        }}
3. GetUserQuizAnswer, sets state of quizUserAnswers
    -request to '/api/ebookQuiz/userAnswers/v2/:ebookUID/:userUUID'
    - returns {mcAnswers, saAnswers} 
        -mcAnswers: ebookUUID, userUUID, quizUUID, quizUserAnswer, chapterUUID (from ebookQuizUserAnswers)
        -saAnswers: ebookUUID, userUUID, quizUUID, quizUserAnswer, chaperUUID, dateCreated, ID (from ebookQuizShortAnswers)

*/
