export async function saveAnnotation(ebookId, page, pageId, color, startOffset, endOffset, highlightedText, highlightedTextBegin, highlightedTextEnd, entireParagraph, notes, bookmark) {
    const request = await fetch(`${window.location.origin}/annotations/add`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
            ebook_contentful_id: ebookId,
            page: page,
            page_id: pageId,
            color: color,
            start_offset: startOffset,
            endOffset: endOffset,
            highlighted_text: highlightedText,
            highlighted_text_begin: highlightedTextBegin,
            highlighted_text_end: highlightedTextEnd,
            entire_paragraph: entireParagraph,
            notes: notes,
            bookmark: bookmark
        })
      })
     
      const data = await request.json()
      displayAnnotation(data.annotation)
      return data.annotation
  };

export function destroyAnnotation(annotationId) {
  fetch(`${window.location.origin}/annotations/${annotationId}`, {
    method: 'DELETE',
    headers: {
      "Content-Type": "application/json",
    }
  }).then(response => response.json())
    .then(obj => {
      console.log(obj)
    })
};

export function getAnnotations(ebookId, page) {
  fetch(`${window.location.origin}/annotations/${ebookId}/${page}`, {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
    },
    // body: JSON.stringify({
    //     ebook_contentful_id: ebookId,
    //     page: page
    // })
  }).then(response => response.json())
    .then(obj => {
      obj.annotations.forEach((annotation)=> {
        displayAnnotation(annotation)
      })
    })
}

export function displayAnnotation(ele) {
  const highlightedText = escapeRegExp(ele.highlightedText);
  const beginText = ele.highlightedTextBegin;
  const highlightedTextEntirePargraph = ele.entirePargraph;
  const highlightedColor = ele.color;
  const highlightedTimeStamp = ele.highlightedTime;
  const notes = ele.notes;
  const bookmark = ele.bookmark;
  const annotationId = ele.id;

  const frameContents = $('#myFrame')
  const frame = $('#frameBody')
  const searchObj = `p:contains(${highlightedTextEntirePargraph})`;

  let annotationsSelector = $(frame).find(searchObj); // add or find
  if (annotationsSelector.length === 0) {
    const fallback = frameContents.find(
      `p:contains(${highlightedTextEntirePargraph})`
    );
    annotationsSelector = $(frame).add(fallback);
  }

  
  annotationsSelector.each((index, annotatedElement) => {
    let element = annotatedElement;
    let content = $(element).text();
    let regexp = new RegExp(highlightedText, 'g');
    let found = content.match(regexp) || [];

    if (found == null || found.length === 0) {
      element = $(frame).add($(searchObj));
      content = $(element).text();
      regexp = new RegExp(highlightedText, 'g');
      found = content.match(regexp) || [];
    }
    
    // if it only appears once in the text
    if (found.length === 1) {
      var instance = new Mark(element);
      // add a temporary class we need to add a container t
      instance.markRegExp(regexp, {
        className: 'highlight-temp',
        acrossElements: true,
        done(cnt) {
          if (cnt === 0) {
            console.error('Unable to add highlight to found element');
          }
        },
        noMatch() {
          console.error(`No match found for ${regexp}`);
        }
      });


      updateAnnotationClass(
        highlightedColor,
        notes,
        highlightedTimeStamp,
        false,
        bookmark,
        false,
        annotationId
      );
    } else {
      // TODO handle multiple matches
      const matching = content.match(beginText);
      if (
        (matching !== null && matching.length === 1) ||
        content === beginText
      ) {
        const stringIndexStart =
          content !== beginText
            ? $(element).text().match(beginText).index + beginText.length
            : 0;
        // I think the 9 will work but I don't know for sure without more testing....
        // it seems that's the added space in the frame
        $(element).markRanges(
          [
            {
              start: stringIndexStart,
              length: highlightedText.length
            }
          ],
          {
            className: 'highlight-temp'
          }
        );
      }

      updateAnnotationClass(
        highlightedColor,
        notes,
        highlightedTimeStamp,
        false,
        bookmark,
        false,
        annotationId
      );
    }
  });
}

function escapeRegExp(stringToGoIntoTheRegex) {
  return stringToGoIntoTheRegex.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
}

function updateAnnotationClass(
  highlightedColor,
  notes,
  highlightedTimeStamp,
  addNoteIcon,
  bookmark,
  addBookmarkIcon,
  annotationId
) {
  const frame = $('#myFrame');

  const highlightTemp = $(frame).find('.highlight-temp');
  highlightTemp.addClass('highlight-' + highlightedColor);
  highlightTemp.addClass('highlighted-container');
  highlightTemp.attr('data-timestamp', highlightedTimeStamp);
  highlightTemp.attr('data-id', annotationId)
  if (notes) {
    const div = $('<div />', {
      class: 'annotation-note',
      'data-note-timestamp': String(highlightedTimeStamp),
      'data-id':annotationId,
      click(e) {
        e.preventDefault();

        const note = $(e.target).parent().find('.post-it');

        // show the note
        if (note.hasClass('hidden')) {
          // $(e.target).parent().find('.post-it').addClass('temp-post-it');
          $(e.target).parent().find('.post-it').removeClass('hidden');
        } else {
          //  $(e.target).parent().find('.post-it').removeClass('temp-post-it');
          $(e.target).parent().find('.post-it').addClass('hidden');
        }
      }
    });

    const postIt = $(`<div class="post-it hidden">${notes}</div>`);

    div.append('<i class="fa fa-file" aria-hidden="true"></i>');

    if (addNoteIcon) {
      $(div).append(postIt);
      $(frame).find(`[data-timestamp="${highlightedTimeStamp}"]`).parents("p").prepend(div);
      return false;
    }

    // this is used when you refresh the page or get the annotation for the first time
    $(div).append(postIt);
    highlightTemp.parents("p").prepend(div);
    // remove the temp class
    highlightTemp.removeClass('highlight-temp');
  }

  if (bookmark) {
    const div = $('<div />', {
      class: 'annotation-bookmark',
      'data-bookmark-timestamp': String(highlightedTimeStamp),
      'data-id':annotationId
    });

    div.append('<i class="fa fa-bookmark" aria-hidden="true"></i>');

    if (addBookmarkIcon) {
      const elements = $(frame).find(
        `[data-timestamp="${highlightedTimeStamp}"]`
      );
      if (elements && elements.length > 0) {
        $(elements[0]).prepend(div);
      }
      // $(frame).find(".highlight-temp").removeClass('highlight-temp');
      return false;
    }
    // this is used when you refresh the page or get the annotation for the first time
    $(frame).find('.highlight-temp').parent("p").prepend(div);
  }

  highlightTemp.removeClass('highlight-temp');
  return true;
}
