import ReactOnRails from 'react-on-rails';

import Ereader from '../bundles/Ereader/components/Ereader';
import SchoolSearch from '../bundles/SchoolSearch/components/SchoolSearch'
import PollResponse from '../bundles/PollResponse/components/PollResponse'

ReactOnRails.register({
    Ereader,
    SchoolSearch,
    PollResponse
});
