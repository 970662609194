import React from 'react';
const NotesBar = ({notes, changePage}) => {

    function toggleNotesBar() {
        $('#notesBar').toggle()
    }

    return (
        <div id="notesBar" className="sidedrawer" style={{right: "0px", display: "none"}}>
            <button type="button" className="btn btn-default btn-sm close-side" onClick={toggleNotesBar}>Close
            </button>
            <p className="side-titles">Notes
            </p>
            <ul className="annotations-notes">
                {notes.map((note) =>
                    <li onClick={() => changePage(note.page, true, true)} key={note.id.toString()}>{note.notes}</li>
                )}
            </ul>
        </div>
    );
}

export default NotesBar;