import React from 'react';

const NotificationPopUp = () => {
    function closePanel() {
        $('#notificationPopUp').css({
            display: 'none'
        });
    }

    return (
        <div className="modal" onClick={closePanel} id="notificationPopUp">
            
            <div className="notes-container">
                <div className="notes-container-child">
                    <h3 className="notes-header" id="notification-modal-title">Notification</h3>
                </div>
                <div id='notification-text'>
                    
                </div>
            </div>
        </div> 
    );
}

export default NotificationPopUp;