import { logActivity } from './sendActivity'

export function recordVideoClicked(pageId, ebookId, chapterId) {

    let parentType = null
    if (chapterId == 'bellRinger') {
        parentType = 'bellRinger'
    } else if (chapterId == 'miniLesson') {
        parentType = 'miniLesson'
    } 


    $('video')
        .on('play', function (e) {
            logActivity("videoPlay", ebookId, pageId, e.target.currentTime, e.target.duration, chapterId, e.target.dataset.contentfulId, parentType)
        });
  
    $('video')
        .on('pause', function (e) {
            if (e.target.currentTime === e.target.duration) {
                logActivity("videoEnd", ebookId, pageId, e.target.currentTime, e.target.duration, chapterId, e.target.dataset.contentfulId, parentType)
            } else {
                logActivity("videoPause", ebookId, pageId, e.target.currentTime, e.target.duration, chapterId, e.target.dataset.contentfulId, parentType)
            }
        });
}

export function recordAudioClicked(pageId, ebookId, chapterId) {

    let parentType = null
    if (chapterId == 'bellRinger') {
        parentType = 'bellRinger'
    } else if (chapterId == 'miniLesson') {
        parentType = 'miniLesson'
    } 

    $('audio')
        .on('play', function (e) {
            logActivity("audioPlay", ebookId, pageId, e.target.currentTime, e.target.duration, chapterId, e.target.dataset.contentfulId, parentType)
        });
  
    $('audio')
        .on('pause', function (e) {
            
            if (e.target.duration === e.target.currentTime) {
                logActivity("audioEnd", ebookId, pageId, e.target.currentTime, e.target.duration, chapterId, e.target.dataset.contentfulId, parentType)
            } else {
                logActivity("audioPause", ebookId, pageId, e.target.currentTime, e.target.duration, chapterId, e.target.dataset.contentfulId, parentType)
            }
           
        });
  
  }