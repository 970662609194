import React, {useState, useEffect} from 'react'

const PollResponse = (props) => {

    function submitResponse(responseId) {
        fetch(`${window.location.origin}/polls/${props.id}/respond`, {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
                response_id: responseId
            })
          })
          
          alert("Thank you for submitting your response")
          window.location.replace(window.location.origin);
    }

    return(
        <div className='pollResponseContainer'>
            <div class='optionsHeader'>
                {props.question}
            </div>
            <div className='optionsContainer'>
                <ul className='pollList'>
                    {props.options.map((option) =>
                        <li className='pollOptionResponse'key={option.id} onClick={() => submitResponse(option.id)}>{option.answer}</li>
                    )}
                </ul>
            </div>
        </div>
    )
}

export default PollResponse
