import React, {useEffect} from 'react';

const AnnotationWidget = ({createAnnotation, deleteAnnotation, addNote, createBookmark}) => {
    useEffect(() => {
        $("#annotool-close").on("click", function(){
            $('#annotationsTools').css({
                display: 'none'
            });
        })
    },[])

    return (
        <div id="annotationsTools">
            <div id="annotool-close">
                <span className="glyphicon glyphicon-remove highlight-close" aria-hidden="true">
                    
                </span>
            </div>
            <div id="highlight-yellow" onClick={() => createAnnotation("yellow")} className="highlight-colors highlight-tools" ></div>
            <div id="highlight-green" onClick={() => createAnnotation("green")} className="highlight-colors highlight-tools" ></div>
            <div id="highlight-blue" onClick={() => createAnnotation("blue")} className="highlight-colors highlight-tools" ></div>
            <div id="highlight-pink" onClick={() => createAnnotation("pink")} className="highlight-colors highlight-tools" ></div>
            <div className="tools">
                <span onClick={() => createBookmark()} id="hightlight-bookmark" className="glyphicon highlight-tools" aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 annotation-svg" fill="none" style={{left: "0px", top: "0px"}} viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
                    </svg>
                </span>
                <span onClick={() => addNote()} id="highlight-notes" className="glyphicon glyphicon-file highlight-tools" aria-hidden="true">
                    
                </span>
                <span onClick={() => deleteAnnotation()} id="highlight-trash" className="glyphicon highlight-tools" aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 annotation-svg" fill="none" style={{left: "0px", top: "0px"}} viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                </span>
            </div>
        </div>

    );
};

export default AnnotationWidget;
