// Taken from certell-web-api
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import moment from 'moment';
import { flattenDeep, get, uniq } from 'lodash';
// import { DEFAULT_LOCALE } from '../../router/utilities/i18n';
import { deliveryClient, getEnvironment, previewClient } from './index';
import { contentfulToHtmlOptions } from './renderer';
// import { findQuizTitle } from '../../repository/quizRepository'; TODO: add this function

// TODO: make this a constant in a more universal location?
const DEFAULT_LOCALE = 'en-US';

export async function getEbooks(
  ids = [],
  ebookStyle,
  locale = DEFAULT_LOCALE,
  includeChapters = false,
  raw = false,
  isTeacher = false
) {
  let ebooks;
  try {
    ebooks = await deliveryClient.getEntries({
      content_type: 'book',
      'sys.id[in]': ids.join(','),
      locale,
      select: !includeChapters
        ? 'sys.id,fields.title,fields.version,fields.css,fields.coverImage,fields.studentCoverImage'
        : ''
    });
  } catch (e) {
    console.error(e);
    return [];
  }
  if (!ebooks.items || ebooks.items.length === 0) {
    return [];
  }
  if (raw) {
    return ebooks;
  }
  return ebooks.items.map((e) =>
    extractEbook(e, ebookStyle, includeChapters, false, isTeacher)
  );
}

export async function getDrafts(raw, includeChapters, ebookStyle) {
  let ebooks;
  try {
    ebooks = await previewClient.getEntries({
      content_type: 'book',
      select: !includeChapters
        ? 'sys.id,fields.title,fields.version,fields.css,fields.coverImage,fields.studentCoverImage'
        : ''
    });
  } catch (e) {
    console.error(e);
    return [];
  }
  if (!ebooks.items || ebooks.items.length === 0) {
    return [];
  }
  if (raw) {
    return ebooks;
  }
  return ebooks.items.map((e) => extractDraft(e, ebookStyle, false, false, true));
}

/**
 *  READ METHODS
 */

/**
 * Chapters part
 */
function extractChapter(
  data,
  ebookStyle,
  isMobileBundle = false,
  isTeacherUserType = false
) {
  if (!data) {
    return {};
  }
  const res = {
    id: data.sys.id
  };

  if (data.fields.title) {
    res.title = data.fields.title;
  }
  if (data.fields.chapterOrder) {
    res.chapterOrder = data.fields.chapterOrder;
  }
  res.parentChapterNumber = data.fields.parentChapterNumber || 0;
  if (data.fields.body) {
    res.body = documentToHtmlString(
      data.fields.body,
      contentfulToHtmlOptions(isMobileBundle, isTeacherUserType, ebookStyle)
    );
  } else {
    res.body = '<div></div>';
  }

  // Don't like adding HTML markup here
  res.teacherBlock = '<div class="teacher-block">';

  if (data.fields.teacherResourcesBlock) {
    res.teacherBlock += documentToHtmlString(
      data.fields.teacherResourcesBlock,
      contentfulToHtmlOptions(isMobileBundle, isTeacherUserType, ebookStyle)
    );
  }

  res.teacherBlock += '</div>';

  return res;
}

function extractSection(
  data,
  ebookStyle,
  isMobileBundle = false,
  isTeacherUserType = false
) {
  if (!data) {
    return '';
  }

  const res = {
    id: data.sys.id
  };

  // Don't like adding HTML markup here
  res.teacherBlock = '<div class="teacher-block">';

  if (data.fields) {
    if (data.fields.title) {
      res.title = data.fields.title;
    }
    res.parentChapterNumber = data.fields.parentChapterNumber || 2;
    if (data.fields.body) {
      res.body = documentToHtmlString(
        data.fields.body,
        contentfulToHtmlOptions(isMobileBundle, isTeacherUserType, ebookStyle)
      );
    } else {
      res.body = '<div></div>';
    }

    if (data.fields.teacherResourcesBlock) {
      res.teacherBlock += documentToHtmlString(
        data.fields.teacherResourcesBlock,
        contentfulToHtmlOptions(isMobileBundle, isTeacherUserType, ebookStyle)
      );
    }
  }

  res.teacherBlock += '</div>';

  return res;
}

function extractModule(
  data,
  ebookStyle,
  isMobileBundle = false,
  isTeacherUserType = false
) {
  if (!data) {
    return '';
  }

  const res = {
    id: data.sys.id
  };

  if (data.fields.title) {
    res.title = data.fields.title;
  }

  res.parentChapterNumber = data.fields.parentChapterNumber || 1;

  if (data.fields.body) {
    res.body = documentToHtmlString(
      data.fields.body,
      contentfulToHtmlOptions(isMobileBundle, isTeacherUserType, ebookStyle)
    );
  } else {
    res.body = '<div></div>';
  }

  res.teacherBlock = '<div class="teacher-block">';
  if (data.fields.teacherResourcesBlock) {
    res.teacherBlock += documentToHtmlString(
      data.fields.teacherResourcesBlock,
      contentfulToHtmlOptions(isMobileBundle, isTeacherUserType, ebookStyle)
    );
  }
  res.teacherBlock += '</div>';

  const sections = [];
  if (data.fields.sections) {
    sections.push(
      data.fields.sections.map((section) =>
        extractSection(section, ebookStyle, isMobileBundle, isTeacherUserType, ebookStyle)
      )
    );
  }
  sections.unshift(res);

  return sections;
}

function extractUnit(data, ebookStyle, isMobileBundle = false, isTeacherUserType = false) {
  if (!data) {
    return {};
  }

  const res = {
    id: data.sys.id
  };

  if (data.fields) {
    if (data.fields.title) {
      res.title = data.fields.title;
    } else {
      res.title = '';
    }
  } else {
    res.title = '';
  }

  if (data.fields) {
    if (data.fields.body) {
      res.body = documentToHtmlString(
        data.fields.body,
        contentfulToHtmlOptions(isMobileBundle, isTeacherUserType, ebookStyle)
      );
    } else {
      res.body = '<div></div>';
    }
  } else {
    res.body = '<div></div>';
  }

  let modules = [];
  if (data.fields) {
    if (data.fields.modules) {
      modules = data.fields.modules
        .filter((module) => module.fields !== undefined)
        .map((module) =>
          extractModule(module, ebookStyle, isMobileBundle, isTeacherUserType, ebookStyle)
        );
    }
  }
  // Don't like adding HTML markup here
  res.teacherBlock = '<div class="teacher-block">';

  if (data.fields) {
    if (data.fields.teacherResourcesBlock) {
      res.teacherBlock += documentToHtmlString(
        data.fields.teacherResourcesBlock,
        contentfulToHtmlOptions(isMobileBundle, isTeacherUserType, ebookStyle)
      );
    }
  }

  res.teacherBlock += '</div>';

  modules.unshift(res);
  return flattenDeep(modules);
}

export async function getChapters(
  ids = [],
  ebookStyle,
  locale = DEFAULT_LOCALE,
  raw = true
) {
  let chapters;
  try {
    chapters = await deliveryClient.getEntries({
      include: 3,
      locale,
      'sys.id[in]': ids.join(',')
    });
  } catch (e) {
    console.error(e);
    return [];
  }
  if (!chapters.items || chapters.items.length === 0) {
    return [];
  }
  if (raw) {
    return chapters;
  }
  return chapters.items.map((c) => extractChapter(c, ebookStyle));
  // return chapters
}

export async function getMultipleChoiceQuestion(
  id,
  withLinks = false,
  locale = DEFAULT_LOCALE
) {
  let questions;
  const query = {
    content_type: 'multipleChoiceQuestion',
    'sys.id': id,
    locale
  };

  try {
    questions = await deliveryClient.getEntries(query);
  } catch (e) {
    console.error(e);
    return [];
  }
  if (!questions.items || questions.items.length === 0) {
    return null;
  }

  const multipleChoiceQuiz = extractMultipleChoiceQuestion(questions.items[0]);

  const book = {};
  const chapter = {};
  const module = {};
  if (withLinks) {
    let linkedItem = null;
    let searchId = multipleChoiceQuiz.quizUUID;
    while (linkedItem === null) {
      const links = await deliveryClient.getEntries({
        links_to_entry: searchId
      });
      if (links && links.items.length > 0) {
        linkedItem = links.items[0];
      } else {
        break;
      }

      if (linkedItem.sys.contentType.sys.id === 'infoBox') {
        searchId = linkedItem.sys.id;
        linkedItem = null;
      } else if (linkedItem.sys.contentType.sys.id === 'chapter') {
        searchId = linkedItem.sys.id;
        chapter.id = linkedItem.sys.id;
        chapter.title = linkedItem.fields.title;
        linkedItem = null;
      } else if (linkedItem.sys.contentType.sys.id === 'book') {
        book.id = linkedItem.sys.id;
        book.title = linkedItem.fields.title;
      } else if (linkedItem.sys.contentType.sys.id === 'module') {
        searchId = linkedItem.sys.id;
        module.id = linkedItem.sys.id;
        module.title = linkedItem.fields.title;
        linkedItem = null;
      }
    }

    multipleChoiceQuiz['module'] = module;
    multipleChoiceQuiz['book'] = book;
    multipleChoiceQuiz['chapter'] = chapter;
  }

  return multipleChoiceQuiz;
}

export function extractMultipleChoiceQuestion(q) {
  return {
    quizUUID: q.sys.id,
    quizQuestion: q.fields.question,
    dateCreated: q.sys.createdAt,
    score: q.fields.score || 0,
    quizAnswers: q.fields.answers.map((a) => {
      if (a.fields) {
        return {
          quizAnswer: a.fields.answer,
          quizAnswerExplanation: a.fields.explanation || '',
          quizAnswerUUID: a.sys.id,
          isCorrect: a.fields.isAnswerCorrect || false
        };
      }
    })
  };
}

export async function getShortAnswerQuestion(
  shortAnswerId,
  withLinks = false,
  locale = DEFAULT_LOCALE
) {
  let questions, question, maxScore;
  const query = {
    content_type: 'shortAnswerQuestion',
    'sys.id': shortAnswerId,
    locale
  };

  try {
    questions = await deliveryClient.getEntries(query);
  } catch (e) {
    console.error(e);
    return [];
  }

  // UUID must not be a contentful id, check ebookQuiz table for legacy quizzes
  if (!questions.items || questions.items.length === 0) {
    // return []
    //TODO: findQuizTitle
    // question = await findQuizTitle(shortAnswerId);
    // if (question.length > 0) {
    //   question = question[0].quizQuestion;
    //   maxScore = 0;
    // } else {
    //   return [];
    // }
  } else {
    question = questions.items[0].fields.question;
    maxScore = questions.items[0].fields.maxScore;
  }

  const data = {
    question: question,
    maxScore: maxScore
  };

  const book = {};
  const chapter = {};
  const module = {};
  const unit = {};
  if (withLinks) {
    let linkedItem = null;
    let searchId = shortAnswerId;
    while (linkedItem === null) {
      const links = await deliveryClient.getEntries({
        links_to_entry: searchId
      });
      if (links && links.items.length > 0) {
        linkedItem = links.items[0];
      } else {
        break;
      }

      if (linkedItem.sys.contentType.sys.id === 'infoBox') {
        searchId = linkedItem.sys.id;
        linkedItem = null;
      } else if (linkedItem.sys.contentType.sys.id === 'chapter') {
        searchId = linkedItem.sys.id;
        chapter.id = linkedItem.sys.id;
        chapter.title = linkedItem.fields.title;
        linkedItem = null;
      } else if (linkedItem.sys.contentType.sys.id === 'book') {
        book.id = linkedItem.sys.id;
        book.title = linkedItem.fields.title;
      } else if (linkedItem.sys.contentType.sys.id === 'module') {
        searchId = linkedItem.sys.id;
        module.id = linkedItem.sys.id;
        module.title = linkedItem.fields.title;
        linkedItem = null;
      } else if (linkedItem.sys.contentType.sys.id === 'unit') {
        searchId = linkedItem.sys.id;
        unit.id = linkedItem.sys.id;
        unit.title = linkedItem.fields.title;
        linkedItem = null;
      }
    }

    data.book = book;
    data.chapter = chapter;
    data.module = module;
    data.unit = unit;
  }

  return data;
}

export async function getAllShortAnswerQuestions(
  shortAnswerId,
  withLinks = false,
  locale = DEFAULT_LOCALE
) {
  let questions, question, maxScore;
  const query = {
    content_type: 'shortAnswerQuestion',
    include: 4,
    'sys.id': req.body.id,
    locale
  };

  try {
    questions = await deliveryClient.getEntries(query);
  } catch (e) {
    console.error(e);
    return [];
  }
  // UUID must not be a contentful id, check ebookQuiz table for legacy quizzes
  if (!questions.items || questions.items.length === 0) {
    // return []
    //TODO: findQuizTitle
    // question = await findQuizTitle(shortAnswerId);
    // if (question.length > 0) {
    //   question = question[0].quizQuestion;
    //   maxScore = 0;
    // } else {
    //   return [];
    // }
  } else {
    question = questions.items[0].fields.question;
    maxScore = questions.items[0].fields.maxScore;
  }

  // questions = questions.map((q) => {
  //   return {
  //     question: q.items[0].fields.question,
  //     maxScore: q.items[0].fields.maxScore,
  //   }
  // })

  const book = {};
  const chapter = {};
  const module = {};
  if (true) {
    let linkedItem = null,
      linkedBook,
      linkedModule,
      linkedChapter;
    let searchId = questions.items[0].sys.id;

    let links = await deliveryClient.getEntries({
      links_to_entry: searchId
    });
    if (links && links.items.length > 0) {
      linkedItem = links.items[0];
    }

    if (linkedItem.sys.contentType.sys.id === 'infoBox') {
      searchId = linkedItem.sys.id;
    } else if (linkedItem.sys.contentType.sys.id === 'chapter') {
      searchId = linkedItem.sys.id;
      chapter.id = linkedItem.sys.id;
      chapter.title = linkedItem.fields.title;
      links = await deliveryClient.getEntries({
        links_to_entry: searchId
      });
      if (links && links.items.length > 0) {
        linkedModule = links.items[0];
        if (linkedModule) {
          module.title = linkedModule.sys.id;
          module.title = linkedModule.fields.title;
          links = await deliveryClient.getEntries({
            links_to_entry: linkedModule.sys.id
          });
          if (links && links.items.length > 0) {
            linkedBook = links.items[0];
            book.title = linkedBook.fields.title;
            book.id = linkedBook.sys.id;
          }
        }
      }
    } else if (linkedItem.sys.contentType.sys.id === 'book') {
      book.id = linkedItem.sys.id;
      book.title = linkedItem.fields.title;
    }

    data.book = book;
    data.chapter = chapter;
    data.module = module;
  }

  return questions;
}

export async function getChapter(id, ebookStyle, locale = DEFAULT_LOCALE, raw = false) {
  const chapters = await deliveryClient.getEntries({
    include: 3,
    'sys.id': id,
    locale
  });
  if (raw) {
    return chapters;
  }
  if (chapters.items && chapters.items.length > 0) {
    return extractChapter(chapters.items[0], ebookStyle);
  }
  return null;
}

export async function getUnit(
  id,
  ebookStyle,
  locale = DEFAULT_LOCALE,
  raw = false,
  isTeacherUserType = false
) {
  const units = await deliveryClient.getEntries({
    include: 10,
    'sys.id': id,
    locale
  });
  if (raw) {
    return units;
  }
  if (units.items && units.items.length > 0) {
    return extractUnit(units.items[0], ebookStyle, false, isTeacherUserType);
  }
  return null;
}

/**
 * EBooks part
 */
function extractEbook(
  data,
  ebookStyle,
  includeChapters = false,
  isMobileBundle = false,
  isTeacherUserType = false
  ) {
  if (!data) {
    return {};
  }
  const res = {
    externalId: data.sys.id,
    title: data.fields.title,
    version: parseInt(data.fields.version, 10),
    css: data.fields.css,
    isPoptential: data.fields.poptentialStyles
  };

  const coverImageField = isTeacherUserType
    ? 'coverImage'
    : 'studentCoverImage';

  if (
    data.fields[coverImageField] &&
    data.fields[coverImageField].fields &&
    data.fields[coverImageField].fields.file
  ) {
    res.coverImage = data.fields[coverImageField].fields.file.url;
    if (!res.coverImage.startsWith('http')) {
      res.coverImage = `https:${res.coverImage}`;
    }
  }

  if (
    data.fields.studentCoverImage &&
    data.fields.studentCoverImage.fields &&
    data.fields.studentCoverImage.fields.file
  ) {
    res.studentCoverImage = data.fields.studentCoverImage.fields.file.url;
    if (!res.studentCoverImage.startsWith('http')) {
      res.studentCoverImage = `https:${res.studentCoverImage}`;
    }
  } else {
    res.studentCoverImage = res.studentCoverImage;
  }

  if (data.fields.units && data.fields.units.length > 0) {
    const result = data.fields.units.map((unit) =>
      extractUnit(unit, ebookStyle, isMobileBundle, isTeacherUserType, ebookStyle)
    );
    res.units = flattenDeep(result);
  }

  if (
    includeChapters &&
    data.fields.chapters &&
    data.fields.chapters.length > 0
  ) {
    res.chapters = data.fields.chapters.map((c) =>
      extractChapter(c, ebookStyle, isMobileBundle, isTeacherUserType, ebookStyle)
    );
  }

  return res;
}

function extractDraft(
  data,
  ebookStyle,
  includeChapters = false,
  isMobileBundle = false,
  isTeacherUserType = false
) {
  if (!data) {
    return {};
  }

  const res = {
    externalId: data.sys.id,
    title: data.fields ? data.fields.title : '',
    version: data.fields ? parseInt(data.fields.version, 10) : '',
    css: data.fields ? data.fields.css : ''
  };

  const coverImageField = isTeacherUserType
    ? 'coverImage'
    : 'studentCoverImage';

  if (data.fields) {
    if (
      data.fields[coverImageField] &&
      data.fields[coverImageField].fields &&
      data.fields[coverImageField].fields.file
    ) {
      res.coverImage = data.fields[coverImageField].fields.file.url;
      if (!res.coverImage.startsWith('http')) {
        res.coverImage = `https:${res.coverImage}`;
      }
    }

    if (isTeacherUserType) {
      if (
        data.fields.studentCoverImage &&
        data.fields.studentCoverImage.fields &&
        data.fields.studentCoverImage.fields.file
      ) {
        res.studentCoverImage = data.fields.studentCoverImage.fields.file.url;
      } else {
        res.studentCoverImage = res.coverImage;
      }
    }

    if (data.fields.units && data.fields.units.length > 0) {
      const result = data.fields.units.map((unit) =>
        extractUnit(unit, ebookStyle, isMobileBundle, isTeacherUserType)
      );
      res.units = flattenDeep(result);
    }

    if (
      includeChapters &&
      data.fields.chapters &&
      data.fields.chapters.length > 0
    ) {
      res.chapters = data.fields.chapters.map((c) =>
        extractChapter(c, ebookStyle, isMobileBundle, isTeacherUserType)
      );
    }
  }
  return res;
}

export async function getEbook(
  id,
  ebookStyle,
  locale = DEFAULT_LOCALE,
  includeChapters = true,
  raw = false,
  isMobileBundle = false,
  isTeacherUserType = true
) {
  const ebook = await deliveryClient.getEntries({
    include: 10,
    content_type: 'book',
    'sys.id': id,
    locale
  });
  // console.log(ebook)
  if (raw) {
    return ebook;
  }
  if (!ebook.items || ebook.items.length === 0) {
    return null;
  }
  return extractEbook(
    ebook.items[0],
    ebookStyle,
    includeChapters,
    isMobileBundle,
    isTeacherUserType
  );
}

export async function getRawAndExtractedBellRinger(
  id,
  ebookStyle,
  locale = DEFAULT_LOCALE,
  includeChapters = true,
  isMobileBundle = false,
  isTeacherUserType = true
) {
  let teacherContent
  let result 
  try {
    result = await deliveryClient.getEntries({
      content_type: 'bellRinger',
      include: 10,
      'sys.id': id,
      locale
    });
    teacherContent = result.items[0].fields;
  } catch (err) {
    console.error(err);
    return null;
  }

  

  let extractedLesson = documentToHtmlString(
    teacherContent.body,
    contentfulToHtmlOptions(true, true, ebookStyle)
  );

  let title = teacherContent.title;
  let imageUrl = teacherContent.featuredImage
    ? teacherContent.featuredImage.fields.file.url
    : teacherContent.imageUrl || null;
  let videoUrl = teacherContent.videoUrl || null;
  let date = teacherContent.date || null;

  extractedLesson = '<br />' + extractedLesson;
  if (imageUrl) {
    extractedLesson = '<img src="' + imageUrl + '" width="650" height="488">' + extractedLesson
  }
  extractedLesson = '<h1 class="entry-title">' + title + '</h1>' + extractedLesson
  if (videoUrl) {
    extractedLesson = extractedLesson + '<iframe src="' + videoUrl +'" width="640" height="360" frameborder="0" >';
  }
  return ([result, extractedLesson])
}

export async function getRawAndExtractedMiniLesson(
  id,
  ebookStyle,
  locale = DEFAULT_LOCALE,
  includeChapters = true,
  isMobileBundle = false,
  isTeacherUserType = true
) {
  let teacherContent
  let result 
  try {
    result = await deliveryClient.getEntries({
      content_type: 'miniLesson',
      include: 10,
      'sys.id': id,
      locale
    });
    teacherContent = result.items[0].fields;
  } catch (err) {
    console.error(err);
    return null;
  }

  

  let extractedLesson = documentToHtmlString(
    teacherContent.body,
    contentfulToHtmlOptions(true, true, ebookStyle)
  );

  let title = teacherContent.title;
  let imageUrl = teacherContent.featuredImage
    ? teacherContent.featuredImage.fields.file.url
    : teacherContent.imageUrl || null;
  let videoUrl = teacherContent.videoUrl || null;
  let date = teacherContent.date || null;

  extractedLesson = '<br />' + extractedLesson;
  if (imageUrl) {
    extractedLesson = '<img src="' + imageUrl + '" width="650" height="488">' + extractedLesson
  }
  extractedLesson = '<h1 class="entry-title">' + title + '</h1>' + extractedLesson
  if (videoUrl) {
    extractedLesson = extractedLesson + '<iframe src="' + videoUrl +'" width="640" height="360" frameborder="0" >';
  }
  return ([result, extractedLesson])
}

export async function getRawAndExtractedEbook(
  id,
  ebookStyle,
  locale = DEFAULT_LOCALE,
  includeChapters = true,
  isMobileBundle = false,
  isTeacherUserType = true
) {
  const ebook = await deliveryClient.getEntries({
    include: 10,
    content_type: 'book',
    'sys.id': id,
    locale
  });

  if (!ebook.items || ebook.items.length === 0) {
    return null;
  }
  let extractedEbook = extractEbook(
    ebook.items[0],
    ebookStyle,
    includeChapters,
    isMobileBundle,
    isTeacherUserType
  );

  return ([ebook, extractedEbook])
}

export async function getTranslations(id, type) {
  
  const ebooks = await deliveryClient.getEntries({
    include: 10,
    content_type: type,
    'sys.id': id,
    locale: '*'
  });

  if (ebooks.items.length < 1) {
    return [];
  }

  const ebook = ebooks.items[0];
  let locales = Object.keys(ebook.fields.title).sort();
  const translations = {
    'en-US': 'English',
    es: 'Spanish',
    fr: 'French',
    he: 'Hebrew',
    el: "Greek",
    'sr-Cyrl-RS': "Serbian (Cyrillic, Serbia)",
    'ps-AF': "Pashto (Afghanistan)",
    ko: "Korean",
    'zh-Hans': "Chinese (Simplified)" 
  };


  const mappedLocales = locales.map((locale) => ({
    [locale]: translations[locale]
  }));

  return mappedLocales
}

export async function getEbookDraft(
  id,
  ebookStyle,
  locale = DEFAULT_LOCALE,
  includeChapters = true,
  raw = false,
  isMobileBundle = false,
  isTeacherUserType = false
) {
  const ebook = await previewClient.getEntries({
    include: 10,
    limit: 1,
    content_type: 'book',
    'sys.id': id,
    locale: 'en-US'
  });
  if (raw) {
    return ebook;
  }
  if (!ebook.items || ebook.items.length === 0) {
    return null;
  }
  return extractDraft(
    ebook.items[0],
    ebookStyle,
    includeChapters,
    isMobileBundle,
    isTeacherUserType
  );
}

export async function getEbookLocales(id) {
  const env = await getEnvironment();

  const ebooks = await env.getEntries({
    content_type: 'book',
    'sys.id': id
  });

  if (ebooks.items.length < 1) {
    return [];
  }

  const ebook = ebooks.items[0];
  return Object.keys(ebook.fields.title).sort();
}

/** Teacher content part * */

export async function getTeacherContent(id, type, ebookStyle, locale = DEFAULT_LOCALE) {
  let teacherContent;
  try {
    const result = await deliveryClient.getEntries({
      content_type: type,
      include: 10,
      'sys.id': id,
      locale
    });
    teacherContent = result.items[0].fields;
  } catch (err) {
    console.error(err);
    return null;
  }

  const content = documentToHtmlString(
    teacherContent.body,
    contentfulToHtmlOptions(true, true, ebookStyle)
  );
  return {
    title: teacherContent.title,
    date: teacherContent.date,
    content,
    imageUrl: teacherContent.featuredImage
      ? teacherContent.featuredImage.fields.file.url
      : teacherContent.imageUrl || null,
    videoUrl: teacherContent.videoUrl || null
  };
}

export async function getBellRingers(filter = {}, ebookStyle) {
  const query = {
    content_type: 'bellRinger',
    order: filter.orderBy ? filter.orderBy : 'sys.createdAt'
  };

  if (filter.tag) {
    query['fields.tags[in]'] = filter.tag;
  }
  if (filter.search) {
    query['fields.body[match]'] = filter.search;
  }

  let matchedBellRingers = [];
  let result = [];

  if (filter.from && filter.to) {
    const from = moment(filter.from, 'YYYY/MM/DD');
    const to = moment(filter.to, 'YYYY/MM/DD');

    for (let m = from; m.diff(to, 'days') <= 0; m.add(1, 'days')) {
      const day = m.date();
      const month = m.month() + 1;
      query['fields.day'] = day;
      query['fields.month'] = month;
      matchedBellRingers = await deliveryClient.getEntries(query);

      result.push(...matchedBellRingers.items);
    }
  } else {
    matchedBellRingers = await deliveryClient.getEntries(query);
    result.push(...matchedBellRingers.items);
  }

  const totalCount = result.length;
  const offset = filter.offset ? parseInt(filter.offset, 10) : 0;
  if (filter.limit) {
    result = result.slice(offset, parseInt(filter.limit, 10) + offset);
  }

  const data = result.map((bellRinger) => {
    const content = documentToHtmlString(
      bellRinger.fields.body,
      contentfulToHtmlOptions(false, false, ebookStyle)
    );
    return {
      id: bellRinger.sys.id,
      title: bellRinger.fields.title,
      date: bellRinger.fields.date,
      content,
      imageUrl: bellRinger.fields.featuredImage
        ? bellRinger.fields.featuredImage.fields.file.url
        : bellRinger.fields.imageUrl || null,
      videoUrl: bellRinger.videoUrl || null,
      day: bellRinger.fields.day,
      month: bellRinger.fields.month,
      year: bellRinger.fields.year
    };
  });

  data.sort((a, b) => {
    if (a.month === b.month) {
      return a.day - b.day;
    }
    return a.month - b.month;
  });

  return {
    data,
    total: totalCount
  };
}

export async function getBellRingersTags(ebookStyle) {
  const query = {
    content_type: 'bellRinger'
  };
  query.select = 'fields.tags';
  query['fields.tags[exists]'] = true;
  const results = await deliveryClient.getEntries(query);
  const tags = [];
  if (results.items) {
    results.items.map((item) => {
      tags.push(...get(item, 'fields.tags'));
      return undefined;
    });
  }

  const sortedTags = tags.sort((a, b) => {
    if (a.toLowerCase() > b.toLowerCase()) {
      return 1;
    }
    return -1;
  });
  return uniq(sortedTags);
}

export async function getBellRingersWithTag(tag, ebookStyle) {
  const query = {
    content_type: 'bellRinger',
    'fields.tags[in]': tag
  };

  const result = await deliveryClient.getEntries(query);
  return result.items.map((bellRinger) => {
    const content = documentToHtmlString(
      bellRinger.fields.body,
      contentfulToHtmlOptions(false, false, ebookStyle)
    );
    return {
      title: bellRinger.fields.title,
      date: bellRinger.fields.date,
      content,
      imageUrl: bellRinger.fields.featuredImage
        ? bellRinger.fields.featuredImage.fields.file.url
        : bellRinger.fields.imageUrl || null,
      videoUrl: bellRinger.videoUrl || null,
      day: bellRinger.fields.day,
      month: bellRinger.fields.month,
      year: bellRinger.fields.year
    };
  });
}
